// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery3
//= require jquery-ui
//= require popper
//= require bootstrap
//= require bootstrap-sprockets
//= require social-share-button
//= require static_pages
//= require_tree .
//= require cocoon

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require('popper.js')
require('jquery')
require('bootstrap')
require("cocoon")

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

$(document).ready( function(){

    $('.cta').wrap('<a href="mailto:work@redhoteskimo.com?subject=Website enquiry"></a>');
    $('.navbar').removeClass('nobg');
    $('#logoHeader').show();
/*
    $('.navbar').addClass('nobg');
    $('#logoHeader').hide();
    
    $(window).scroll(function(){                          
       scroll(this);
    });
*/
})


